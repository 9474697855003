/* eslint react/destructuring-assignment: 0 */
import React from 'react'
import styled from '@emotion/styled'
import { Global, css } from '@emotion/core'
import { SEO, Wrapper, Container, Row, Col, SectionHeader, Listing, Navigation, Footer } from '../components'
import { LocaleContext } from '../components/Layout'
import Img from "gatsby-image"

const LocaleSwitcherStyle = theme => css`
  [data-name='locale-switcher'] {
    color: ${theme.colors.greyBlue};
    a {
      color: white;
    }
  }
`
const Privacy = ({ location, pageContext: { locale } }) => {
  const lang = React.useContext(LocaleContext)
  const i18n = lang.i18n[lang.locale]
  return (
    <>
      <Global styles={LocaleSwitcherStyle} />
      <SEO 
        title={`Privacy Policy | ${i18n.defaultTitle}`}
        desc= "Privacy policy"
        pathname={location.pathname}
        locale={locale} 
      />
        <Navigation locale={locale} ></Navigation>
        <Wrapper background="#f5f5f5">
            <Container>
            <Row>
                <Col>
                    <p className="tagline">About</p>
                    <h1 className="position">Privacy Policy</h1>
                </Col>
            </Row>
            </Container>
        </Wrapper>
        <Wrapper>
            <Container>
            <Row>
                <Col><h2>Your privacy is important to us. </h2>
                <p>We take the protection of your personal data very seriously. It is our policy to keep your personal data private and we will process it according to the data protection legislation and this Privacy Notice. We take technical and organizational measures to ensure that privacy requirements are met.</p>
                <p>The following information will give you an overview as to how your personal data is processed when you visit our website, including its section for job applications (“Jobs”) and our blog.</p>
                <h3>1. Overview</h3>
                <h4><b>What is personal data?</b></h4>
                <p>While using our website, we may ask you to provide certain personal information that can be used to contact or identify you. Personal data may include, but is not limited to: Email address, First name and last name, Phone number, Address and IP.</p>
                <h4><b>How do we collect data?</b></h4>
                <p>We collect your data when you communicate to us through our website, for example, when you fill a contact form or a job application form. In addition to this, we may use third-party Service providers to monitor and analyze the use of our Service. You can find further details in the section [Analytics] of this page.</p>
                <h4><b>Processings your personal data?</b></h4>
                <p>Dceed Bilişim Teknolojileri A.Ş. is responsible for the collection, processing and use of your personal data, according to the EU General Regulation on Data Protection. You can find further details in the section [Imprint] of this website.</p>
                <h3>Logfiles</h3>
                <p>Dceed Bilişim Teknolojileri A.Ş. temporarily stores technical data which your browser transmits in so-called log files. Technical data consists of IP Address, date and time of your visit, list of file requests from the server. These data are only stored for security purposes and not transferred to third parties.</p>
                <h3>Cookies </h3>
                <p>Cookies are small text files which are saved by your internet browser and stored on your computer. They are not used to process or store your personal data.</p>
                <p>We store several types of cookies to provide our visitors the best user experience. Following types are used on this website.</p>
                <ul>
                  <li>Functional cookies: understand and save user's preferences for future visits.</li>
                  <li>Analytics cookies: aggregate data about site traffic and interactions to offer better site experiences</li>
                </ul>
                <p>You can adjust the browser settings to turn off all cookies or individual ones. Each browser provides these options in different styles and screens, we would suggest you read the help menu of your browser.</p>
                <h3>Contact Forms</h3>
                <p>When you fill out one of our contact forms, we store all the field details with your data. This data is only stored for understanding your request and having it answered without missing any details.</p>
                <h3>Job Applications</h3>
                <p>When job candidates fill out one of our job application forms on our website, we store all the data as well as the any uploaded the documents. Job applications are retained on our server for a longer period in our talent pool for further contact purposes. You can revoke this explicit consent anytime by sending email to contact@dceed.com without any given reason.</p>
                <h3>Tools for Analysis</h3>
                <p>Our website uses Google Analytics to analyze how our visitors use this website.</p>
                <p>For more information on data processing by Google Analytics information and the Google Privacy Policy, please visit <a href="https://support.google.com/analytics/answer/6004245?hl=en">https://support.google.com/analytics/answer/6004245?hl=en</a>. If you are willing to prevent your data from being used by Google Analytics, you can install Google Analytics Opt-Out addon on your browser. For further reference please visit: <a href="https://tools.google.com/dlpage/gaoptout?hl=en">https://tools.google.com/dlpage/gaoptout?hl=en</a></p>
                <h3>Contact Details</h3>
                <p>If you have any questions or request regarding our privacy policy, please contact us under <a href="mailto:contact@dceed.com">contact@dceed.com</a></p>
                <p>Dceed Bilişim Teknolojileri A.Ş.<br/>Ünalan Mh. Libadiye Cd. No:82 <br/>Emaar Square Sitesi E blok Kat: 29 <br/>Üsküdar, İstanbul<br/><a href="mailto:contact@dceed.com">contact@dceed.com</a></p>
                </Col>
            </Row>
            </Container>
        </Wrapper>
    <Footer locale={locale} />
    </>
  )
}
export default Privacy